body {
  padding: 0;
  font-family: "Source Serif Pro";
}
h1 {
  font-size: 2.5em;
  margin: 0;
}
h3 {
  font-size: 1.45em;
  margin: 0;
  text-align:center;
  font-weight: 400;
}
.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
textarea,
input {
  width: 100%;
  border: 3px solid #f87171;
  font-size: 1.35em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  font-family: "Source Serif Pro";
}
.form-wrapper {
  width: min(90vw, 60em);
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items:center;
  padding: 0;
  margin: 0;
}
textarea {
  height: 42.5vh;
}
button {
  font-family: "Source Serif Pro";
  font-size: 1.45em;
  color: white;
  background: #f87171;
  border: none;
  padding: 0.1em 0.8em;
  cursor: pointer;
  margin-left: auto;
}
p {
  font-size: 1.35em;
  width: min(95vw, 45em);
  text-indent: 2em;
  margin: 0;
}
h5 {
  margin: 0;
  font-size: 2em;
}
ul {
  font-size: 1.35em;
  margin: 0;
}
a {
  color: #f87171;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
